import { render, staticRenderFns } from "./VerifyEmail.vue?vue&type=template&id=978f764e&"
import script from "./VerifyEmail.vue?vue&type=script&lang=js&"
export * from "./VerifyEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBtn,VCard,VCardActions,VCardText,VCol,VContainer,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4154658723/src/playtient-web-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('978f764e')) {
      api.createRecord('978f764e', component.options)
    } else {
      api.reload('978f764e', component.options)
    }
    module.hot.accept("./VerifyEmail.vue?vue&type=template&id=978f764e&", function () {
      api.rerender('978f764e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/VerifyEmail.vue"
export default component.exports