import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=3c137f17&scoped=true&"
import script from "./Team.vue?vue&type=script&lang=js&"
export * from "./Team.vue?vue&type=script&lang=js&"
import style0 from "./Team.vue?vue&type=style&index=0&id=3c137f17&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c137f17",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VContainer,VDialog,VForm,VIcon,VList,VListItem,VListItemTitle,VMenu,VRow,VSpacer,VTextField,VTextarea,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4154658723/src/playtient-web-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c137f17')) {
      api.createRecord('3c137f17', component.options)
    } else {
      api.reload('3c137f17', component.options)
    }
    module.hot.accept("./Team.vue?vue&type=template&id=3c137f17&scoped=true&", function () {
      api.rerender('3c137f17', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Team.vue"
export default component.exports