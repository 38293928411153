import { render, staticRenderFns } from "./Phq9Popup.vue?vue&type=template&id=84831ea8&scoped=true&"
import script from "./Phq9Popup.vue?vue&type=script&lang=js&"
export * from "./Phq9Popup.vue?vue&type=script&lang=js&"
import style0 from "./Phq9Popup.vue?vue&type=style&index=0&id=84831ea8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84831ea8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardTitle,VDialog})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4154658723/src/playtient-web-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('84831ea8')) {
      api.createRecord('84831ea8', component.options)
    } else {
      api.reload('84831ea8', component.options)
    }
    module.hot.accept("./Phq9Popup.vue?vue&type=template&id=84831ea8&scoped=true&", function () {
      api.rerender('84831ea8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Phq9Popup.vue"
export default component.exports